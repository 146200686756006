// Api
import TitleApi from '@/api/TitleApi';

// Utils
import FormatUtil from '@/utils/FormatUtil';

const mutationTypes = {
    SET_LIST: 'SET_LIST',
    SET_IN_FLIGHT: 'SET_IN_FLIGHT',
    SET_AUTHORIZED_ASSIGNEE_LIST: 'SET_AUTHORIZED_ASSIGNEE_LIST',
};

const actionTypes = {
    getList: 'getList',
    setList: 'setList',
    assignTitle: 'assignTitle',
    assignTitleList: 'assignTitleList',
    unassignTitleList: 'unassignTitleList',
    getAuthorizedAssigneeList: 'getAuthorizedAssigneeList',
    reset: 'reset',
};

export default {
    namespaced: true,
    state: {
        /** @type { Array } */
        list: [],
        /** @type { Boolean } - used to tell status of this.list */
        inFlight: false,

        /** @type { assignee[] } */
        authorizedAssigneeList: [],
    },
    getters: {
        /** @returns { Number } */
        titleCount: ( state ) => state.list.length,
        /** @returns { Boolean } */
        hasTitles: ( state, getters ) => getters.titleCount > 0,

        /** @returns { Array } of titles */
        formattedList: ( state, getters ) => (
            getters.hasTitles
                ? state.list
                    .map( ( title ) => ( {
                        id: title.id,
                        productCode: title.productCode,
                        description: FormatUtil.truncateText( title.description, 35 ),
                        primarySupplier: title.primarySupplier && title.primarySupplier.name,
                        listPrice: title.listPrice,
                        shelfDate: title.shelfDate,
                        sectionStartDate: title.sectionStartDate,
                        listingCreateDate: title.listingCreateDate,
                        lastResearchedDate: title.lastResearchedDate,
                        researcherName: title.researcherName || '',
                        researcherUserName: title.researcherUserName || '',
                        listingStatus: title.listingStatus,
                    } ) )
                : []
        ),

        /** @returns { Array } of titles that have a researcherUserName */
        assignedList: ( state, getters ) => getters.formattedList.filter( ( title ) => title.researcherUserName !== '' ),
        /** @returns { Number } */
        assignedCount: ( state, getters ) => getters.assignedList.length,

        /** @returns { Array } of titles that DO NOT have a researcherUserName */
        unassignedList: ( state, getters ) => getters.formattedList.filter( ( title ) => title.researcherUserName === '' ),
        /** @returns { Number } */
        unassignedCount: ( state, getters ) => getters.unassignedList.length,

        /** @returns { String[] } */
        uniqueResearchers: ( state ) => (
            [ ...new Set( state.list.map( ( title ) => title.researcherName ) ) ]
                .filter( ( username ) => !!username )
                .sort()
        ),

        /** @returns { Number } */
        authorizedAssigneeCount: ( state ) => state.authorizedAssigneeList.length,
        /** @returns { Boolean } */
        hasAuthorizedAssigneeList: ( state, getters ) => getters.authorizedAssigneeCount > 0,
    },
    mutations: {
        [ mutationTypes.SET_LIST ]( state, { list } ) {
            state.list = list;
        },
        [ mutationTypes.SET_IN_FLIGHT ]( state, { boolean } ) {
            state.inFlight = boolean;
        },
        [ mutationTypes.SET_AUTHORIZED_ASSIGNEE_LIST ]( state, { list } ) {
            state.authorizedAssigneeList = list;
        },
    },
    actions: {
        [ actionTypes.reset ]( { commit } ) {
            commit( mutationTypes.SET_LIST, { list: [] } );
            commit( mutationTypes.SET_IN_FLIGHT, { boolean: false } );
            commit( mutationTypes.SET_AUTHORIZED_ASSIGNEE_LIST, { list: [] } );
        },
        /**
         * Get the list of assignable titles
         */
        async [ actionTypes.getList ]( context ) {
            context.commit( mutationTypes.SET_IN_FLIGHT, { boolean: true } );
            try {
                const { data } = await TitleApi.getTitlesToAssign( {
                    authToken: context.rootGetters[ 'User/authString' ],
                } );
                context.commit( mutationTypes.SET_LIST, {
                    list: data.titleResearchListDTOList,
                } );
            } catch ( error ) {
                console.log( `Failed to get the list of assignable titles - ${ error.response.data.message }` );
            } finally {
                context.commit( mutationTypes.SET_IN_FLIGHT, { boolean: false } );
            }
        },

        async [ actionTypes.setList ]( context, { list } ) {
            context.commit( mutationTypes.SET_LIST, { list } );
        },

        /**
         * Assign a SINGLE title to an assignee
         */
        async [ actionTypes.assignTitle ]( context, { assignee, productCode } ) {
            try {
                await TitleApi.assignTitleToResearcher( {
                    authToken: context.rootGetters[ 'User/authString' ],
                    assignee,
                    productCode,
                } );

                context.dispatch( 'setSuccessNotification', 'The title assignment was successful!', { root: true } );
            } catch ( error ) {
                console.log( 'Error in assignTitle: ', error );
                context.dispatch( 'setErrorNotification', `Title assignment failed: ${ error.message }`, { root: true } );
            }
        },

        /**
         * Assign an array of titles to an assignee
         */
        async [ actionTypes.assignTitleList ]( context, { assignee, titleList } ) {
            try {
                await TitleApi.assignTitleListToResearcher( {
                    authToken: context.rootGetters[ 'User/authString' ],
                    assignee,
                    titleList,
                } );

                const message = titleList.length > 1
                    ? `Successfully assigned ${ titleList.length } titles`
                    : 'Successfully assigned a title';
                context.dispatch( 'setSuccessNotification', message, { root: true } );
            } catch ( error ) {
                console.log( 'Error in assignTitleList action: ', error );

                const message = `Failed to assign selected titles - ${ error.response.data.message }`;
                context.dispatch( 'setErrorNotification', message, { root: true } );
            }
        },

        /**
         * Unassign an array of titles
         */
        async [ actionTypes.unassignTitleList ]( context, { titleList } ) {
            try {
                await TitleApi.assignTitleListToResearcher( {
                    authToken: context.rootGetters[ 'User/authString' ],
                    assignee: null,
                    titleList,
                } );

                const message = titleList.length > 1
                    ? `Successfully unassigned ${ titleList.length } titles`
                    : 'Successfully unassigned a title';
                context.dispatch( 'setSuccessNotification', message, { root: true } );
            } catch ( error ) {
                console.log( 'Error in unassignTitleList action: ', error );
                const message = `Failed to unassign selected titles - ${ error.response.data.message }`;
                context.dispatch( 'setErrorNotification', message, { root: true } );
            }
        },

        /**
         * Get a list of users that are authorized to Assign and Research titles
         */
        async [ actionTypes.getAuthorizedAssigneeList ]( context ) {
            try {
                const { data } = await TitleApi.getAuthorizedAssignableUsersList( {
                    authToken: context.rootGetters[ 'User/authString' ],
                } );
                context.commit( mutationTypes.SET_AUTHORIZED_ASSIGNEE_LIST, {
                    // Sort alphabetically by username
                    list: data.users.sort( ( a, b ) => ( a.username > b.username ? 1 : -1 ) ),
                } );
            } catch ( error ) {
                const message = `Failed to get the list of users for title assignment - ${ error.response.data.message }`;
                context.dispatch( 'setErrorNotification', message, { root: true } );
            }
        },
    },
};
